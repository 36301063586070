<template>
  <div id="products-list-list-view" class="products-list-container">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'AssignmentsAndDiscount'">
    </breadcrumb-base>

    <!--    Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div class="">
        <div class="vx-row my-2">
          <div class="vx-col md:w-3/7">
            <div class="vx-row m-4">
              <vs-select autocomplete v-model="selectedProduct" class="select-large"
                         :label="$t('Product')">
                <vs-select-item :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="item" :text="item"
                                v-for="(item,index) in productsFilterArray"/>
              </vs-select>
              <vs-select autocomplete v-model="selectedDenomination" class="pl-4 select-large"
                         :label="$t('Denomination')">
                <vs-select-item :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="item" :text="item | germanNumberFormat"
                                v-for="(item,index) in denominationsFilterArray"/>
              </vs-select>
              <div class="pl-4"></div>
              <vs-select autocomplete v-model="selectedProductType" class="select-large"
                         :label="$t('ProductType')" @input="onProductTypeSelected">
                <vs-select-item :value="null" :text="$t('All')"/>
                <vs-select-item :key="index.key" :value="item.key" :text="item.name"
                                v-for="(item,index) in productTypes"/>
              </vs-select>
            </div>
          </div>
          <div class="vx-col md:w-1/7">
            <div class="pt-5">
              <div class="vx-col">
                <h4><strong>{{ $t('CustomerName') }}</strong></h4>
                <h5>{{ customer.first_name || '' }} {{ customer.last_name || '' }}</h5>
              </div>
              <div class="vx-col py-4">
                <h4><strong>{{ $t('CustomerID') }}</strong></h4>
                <h5>{{ customer.customer_id || '' }}</h5>
              </div>
              <div class="vx-row py-4">
                <p class="px-3 dark-theme-text">{{ $t('ProductName') }}</p>
                <vs-checkbox v-model="showProductName"></vs-checkbox>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-3/7">
            <div class="vx-row m-4">
              <vs-select autocomplete v-model="selectedAssignedProduct" class="select-large"
                         :label="$t('Product')">
                <vs-select-item :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="item" :text="item"
                                v-for="(item,index) in assignedProductsFilterArray"/>
              </vs-select>
              <vs-select autocomplete v-model="selectedAssignedDenomination"
                         class="pl-4 select-large"
                         :label="$t('Denomination')">
                <vs-select-item :value="null" :text="$t('All')"/>
                <vs-select-item :key="index" :value="item" :text="item | germanNumberFormat"
                                v-for="(item,index) in assignedDenominationsFilterArray"/>
              </vs-select>
              <div class="pl-4"></div>
              <vs-select autocomplete v-model="selectedProductType" class="select-large"
                         :label="$t('ProductType')" @input="onProductTypeSelected">
                <vs-select-item :value="null" :text="$t('All')"/>
                <vs-select-item :key="index.key" :value="item.key" :text="item.name"
                                v-for="(item,index) in productTypes"/>
              </vs-select>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-3/7">
            <!--    Empty List State -->
            <transition name="fade">
              <div class="h-screen flex w-full bg-img"
                   v-if="products.length === 0 && errorFetching">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                  <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                       class="mx-auto mb-4 max-w-full">-->
                  <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                </div>
              </div>
            </transition>
            <vs-table-modified v-show="products.length > 0 && !errorFetching" ref="table"
                               :data="products"
            >

              <template slot="thead">
                <vs-th>
                  <vs-checkbox @input="onProductCheckedAll" v-model="checkedAll"></vs-checkbox>
                </vs-th>
                <vs-th v-if="!showProductName">{{ $t('Image') }}</vs-th>
                <vs-th v-if="showProductName">{{ $t('Product') }}</vs-th>
                <vs-th>{{ $t('Denomination') }}<span
                  v-if="activeUserInfo.account_role == 1"> - {{ $t('BuyingDiscounts') }}</span>
                </vs-th>
                <vs-th>{{ $t('Discount') }}</vs-th>
                <vs-th>{{ $t('Action') }}</vs-th>
              </template>


              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="tr.id"
                       v-for="(tr, indextr) in data"
                       :class="[slabDiscounts[tr.id] ? 'tr-update' : '']">

                  <vs-td>
                    <vs-checkbox
                      @input="onProductChecked"
                      v-model="productsSelectionChecks[tr.id.toString()]"
                      :vs-value="tr.id.toString()"></vs-checkbox>
                  </vs-td>

                  <vs-td v-if="!showProductName">
                    <img :src="tr.product_image" :alt="tr.product_name" class="product-image"/>
                  </vs-td>
                  <vs-td v-if="showProductName">
                    <p>
                      {{ tr.product_name }}
                    </p>
                  </vs-td>

                  <vs-td>
                    <div class="vs-col">
                      <p class=""
                         style="font-weight: bold;">{{ tr.value | germanNumberFormat }}
                        <span v-if="activeUserInfo.account_role == 1" class=""
                              style=""> - {{ tr.self.discount }} %</span>
                      </p>
                    </div>
                  </vs-td>
                  <vs-td>
                    <vs-input
                      class="vs-inputx inputx"
                      :placeholder="$t('Discount')"
                      v-model="discount[tr.id]"
                      min="0" max="100"
                      @input="changedDiscount($event, tr.id)"/>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <vs-button radius color="success"
                               type="filled" icon-pack="feather"
                               icon="icon-plus"
                               @click="addConfigToAssigned(tr)"></vs-button>
                  </vs-td>

                </vs-tr>
                </tbody>
              </template>
            </vs-table-modified>
          </div>
          <div class="vx-col md:w-1/7">
            <div class="mt-24"></div>
            <div v-if="discountSlabs.length === 0">
              <p class="text-center">{{ $t('NoDiscountSlabs') }}</p>
              <vs-button class="mt-6 flex" style="margin: auto" type="filled"
                         to="/discount-slabs/add">
                {{ $t('AddDiscountSlab') }}
              </vs-button>
            </div>
            <div v-else>
              <vs-select :no-data="$t('NoDiscountSlabs')" autocomplete
                         v-model="selectedDiscountSlab" class="select-large pt-6 w-full"
                         :label="$t('DiscountSlab') + '*'"
                         @change="onDiscountSlabChanged"
                         :danger="discountSlabError" :dangerText="$t('DiscountSlabError')">
                <vs-select-item :key="index" :value="item"
                                :text="(item.plan_name) + ' - ' + (item.plan_discount) + ' %'"
                                v-for="(item,index) in discountSlabs"/>
              </vs-select>

              <div class="mt-8"></div>
              <vs-button class="mt-12 flex" style="margin: auto" type="filled"
                         @click="addConfigToAssigned(null, true)">
                {{ $t('Save') }} {{ selectedProductsLength + assignedSelectedProductsLength }}
                {{ $t('Changes') }}
              </vs-button>
              <!--              <div class="mt-4"></div>-->
              <!--              <vs-button class="mt-12 flex" v-if="assignedSelectedProductsLength > 0"-->
              <!--                         style="margin: auto" type="filled" color="success"-->
              <!--                         @click="removeConfigFromAssigned(null, true, true)">Update {{assignedSelectedProductsLength}}</vs-button>-->
              <div class="mt-4"></div>
              <vs-button class="mt-12 flex" v-if="assignedSelectedProductsLength > 0"
                         style="margin: auto" type="filled" color="danger"
                         @click="removeConfigFromAssigned(null, true)">{{ $t('Remove') }}
                {{ assignedSelectedProductsLength }}
              </vs-button>
            </div>
          </div>
          <div class="vx-col md:w-3/7">
            <!--    Empty List State -->
            <transition name="fade">
              <div class="h-screen flex w-full bg-img"
                   v-if="products.length === 0 && errorFetching">
                <div
                  class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                  <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                       class="mx-auto mb-4 max-w-full">-->
                  <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
                </div>
              </div>
            </transition>
            <vs-table-modified v-show="assignedProducts.length > 0 && !errorFetching" ref="table"
                               :data="assignedProducts"
            >

              <template slot="thead">
                <vs-th>
                  <vs-checkbox @input="onAssignedProductCheckedAll"
                               v-model="assignedCheckedAll"></vs-checkbox>
                </vs-th>
                <vs-th v-if="!showProductName">{{ $t('Image') }}</vs-th>
                <vs-th v-if="showProductName">{{ $t('Product') }}</vs-th>
                <vs-th>{{ $t('Denomination') }}<span
                  v-if="activeUserInfo.account_role == 1"> - {{ $t('BuyingDiscounts') }}</span>
                </vs-th>
                <vs-th>{{ $t('Discount') }}</vs-th>
                <vs-th>{{ $t('Limit') }}</vs-th>
                <vs-th>{{ $t('Action') }}</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data"
                       :class="[slabDiscounts[tr.id] ? 'tr-update' : '']">

                  <vs-td>
                    <vs-checkbox @input="onAssignedProductChecked"
                                 v-model="assignedProductsSelectionChecks[tr.pad_id.toString()]"
                                 :vs-value="tr.id.toString()"></vs-checkbox>
                  </vs-td>

                  <vs-td v-if="!showProductName">
                    <img :src="tr.product_image" :alt="tr.product_name" class="product-image"/>
                  </vs-td>
                  <vs-td v-if="showProductName">
                    <p>
                      {{ tr.product_name }}
                    </p>
                  </vs-td>

                  <vs-td>
                    <div class="vs-col">
                      <p class=""
                         style="font-weight: bold;">{{ tr.value | germanNumberFormat }}
                        <span v-if="activeUserInfo.account_role == 1" class=""
                              style=""> - {{ tr.self.discount }} %</span>
                      </p>
                    </div>
                  </vs-td>
                  <vs-td>
                    <vs-input class="vs-inputx inputx" v-model="assignedDiscounts[tr.id]"/>
                  </vs-td>
                  <vs-td>
                    <vs-input class="vs-inputx inputx" v-model="assignedLimit[tr.id]"/>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <vs-button radius color="danger" type="filled" icon-pack="feather"
                               icon="icon-minus" @click="removeConfigFromAssigned(tr)"></vs-button>
                  </vs-td>

                </vs-tr>
                </tbody>
              </template>
            </vs-table-modified>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'ProductAssignmentDiscounts',
  data() {
    return {
      afterDiscountLoadFirst: false,
      showProductName: false,
      checkedAll: false,
      productsSelectionChecks: {},
      assignedCheckedAll: false,
      assignedProductsSelectionChecks: {},
      customer: {},
      isMounted: false,
      isInit: true,
      errorFetching: false,
      discount: {},
      slabDiscounts: {},
      assignedDiscounts: {},
      productLimits: {},
      assignedLimit: {},
      selectedProduct: null,
      selectedAssignedProduct: null,
      selectedDenomination: null,
      selectedAssignedDenomination: null,
      discountSlabError: false,
      productsFilterArray: [],
      assignedProductsFilterArray: [],
      denominationsFilterArray: [],
      assignedDenominationsFilterArray: [],
      selectedDiscountSlab: null,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Customers', i18n: 'Customers', url: '/customers/view'},
        {title: 'Assignments and discount', i18n: 'AssignmentsAndDiscount', active: true},
      ],
      selectedProductType: null,
      productTypes: [
        {
          'name': 'Cash Cards',
          'key': 'cash card',
        },
        {
          'name': 'Gift Cards',
          'key': 'gift card',
        },
        {
          'name': 'Calling Cards',
          'key': 'calling card',
        },
      ],
      updateDiscountSlabsOnUpdate: false,
    }
  },
  props: {
    customerId: {
      type: String | Number,
    },
  },
  computed: {
    products() {
      return this.$store.state.pad.products.filter((p) => {
        if (this.selectedProduct == null) return true
        return p.product_name === this.selectedProduct
      }).filter((p) => {
        if (this.selectedDenomination == null) return true
        return p.value == this.selectedDenomination
      })
    },
    allProducts() {
      return this.$store.state.pad.products;
    },
    allAssignedProducts() {
      return this.$store.state.pad.productsAssigned;
    },
    assignedProducts() {
      return this.$store.state.pad.productsAssigned.filter((p) => {
        if (this.selectedAssignedProduct == null) return true
        return p.product_name === this.selectedAssignedProduct
      }).filter((p) => {
        if (this.selectedAssignedDenomination == null) return true
        return p.value == this.selectedAssignedDenomination
      })
    },
    discountSlabs() {
      return this.$store.state.pad.discountSlabs
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    },
    selectedProductsLength() {
      return Object.values(this.productsSelectionChecks).filter((v) => v != null).length
    },
    assignedSelectedProductsLength() {
      return Object.values(this.assignedProductsSelectionChecks).filter((v) => v != null).length
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    updateDiscountSlab() {
      this.discountSlabs.forEach((d) => {
        if (d.plan_name === this.customer.discount_plan) {
          this.selectedDiscountSlab = d;
          setTimeout(() => {
            this.onDiscountSlabChanged()
          }, 1000)
        }
      })
      if (this.selectedDiscountSlab == null) {
        // this.selectedDiscountSlab = this.discountSlabs[0];
        // setTimeout(() => {
        //   this.onDiscountSlabChanged()
        // }, 1000)
      }
    },
    onProductTypeSelected() {
      this.loadProducts()
    },
    changedDiscount(val, id) {
      const obj = this.discount
      this.discount = {}
      obj[id] = val
      this.discount = obj
    },
    fetchDiscountSlabsProducts() {
      const payload = {
        'discount_slab_id': this.selectedDiscountSlab.plan_id,
      }
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabProducts', payload)
        .then((data) => {
          this.$vs.loading.close()
          // productsData
          if (data.message_type !== 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          } else {
            const assignedProductsData = data.product_configuration_slabs
            const obj = {}
            if (assignedProductsData.length === 0) {
              Object.keys(this.slabDiscounts).forEach((p) => {
                obj[p] = "";
              })
              this.slabDiscounts = obj
            } else {
              assignedProductsData.forEach((p) => {
                obj[p.configuration_id.toString()] = p.discount
              })
              this.slabDiscounts = obj
            }
            this.updateDiscountsFromSlab()
          }
          if (!this.afterDiscountLoadFirst) {
            this.loadProducts()
            this.afterDiscountLoadFirst = true;
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: this.$t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    updateDiscountsFromSlab() {
      const obj = {}
      this.discount = {}
      this.products.forEach((k) => {
        obj[k.id] = this.slabDiscounts[k.id.toString()] || obj[k.id.toString()]
      })
      this.discount = obj

      const obj2 = this.assignedDiscounts
      this.assignedDiscounts = {}
      this.allAssignedProducts.forEach((k) => {
        if (this.slabDiscounts[k.id.toString()] == "") {
          obj2[k.id] = ""
        } else {
          obj2[k.id] = obj2[k.id] || this.slabDiscounts[k.id.toString()]
        }
      })
      this.assignedDiscounts = obj2

      // Assign Product Limit
      const limitObj = {}
      this.assignedLimit = {}
      console.log("this.productLimits")
      console.log(this.productLimits)
      this.allAssignedProducts.forEach((k) => {
        limitObj[k.id.toString()] = this.productLimits[k.id.toString()] || 0
      })
      this.assignedLimit = limitObj
      console.log(this.assignedLimit)

    },
    onDiscountSlabChanged(value) {
      if (this.updateDiscountSlabsOnUpdate) {

      }
      this.fetchDiscountSlabsProducts()
      this.discountSlabError = false
    },
    onProductChecked(value) {
      // this.updateDiscountsFromSlab()
    },
    onProductCheckedAll(value) {
      if (value) {
        const obj = {}
        this.products.forEach((k) => {
          obj[k.id] = k.id.toString()
        })
        this.productsSelectionChecks = obj
      } else {
        this.productsSelectionChecks = {}
      }
      // this.updateDiscountsFromSlab()
    },
    onAssignedProductChecked(value) {

    },
    onAssignedProductCheckedAll(value) {
      if (value) {
        const obj = {}
        this.allAssignedProducts.forEach((k) => {
          obj[k.pad_id] = k.id.toString()
        })
        this.assignedProductsSelectionChecks = obj
      } else {
        this.assignedProductsSelectionChecks = {}
      }
    },
    addConfigToAssigned(config, byArray) {
      let hasBreak = false
      for (let i = 0; i < Object.keys(this.productsSelectionChecks).length; i++) {
        const keys = Object.keys(this.productsSelectionChecks)
        const pd = this.productsSelectionChecks[keys[i]]
        this.discount[pd] = (this.discount[pd] || '0').toString().replace(",", ".")
      }
      for (let i = 0; i < Object.keys(this.productsSelectionChecks).length; i++) {
        const keys = Object.keys(this.productsSelectionChecks)
        let pd = this.productsSelectionChecks[keys[i]]
        if (pd != null) {
          if (Number(this.discount[pd]) < 0 || Number(this.discount[pd]) > 100) {
            hasBreak = true
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: 'Entered discount is invalid',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
            break
          }
        } else {
          pd = 0;
        }
      }
      for (let i = 0; i < Object.keys(this.assignedProductsSelectionChecks).length; i++) {
        const keys = Object.keys(this.assignedProductsSelectionChecks)
        const pd = this.assignedProductsSelectionChecks[keys[i]]
        this.assignedDiscounts[pd] = this.assignedDiscounts[pd].toString().replace(",", ".")
      }
      for (let i = 0; i < Object.keys(this.assignedProductsSelectionChecks).length; i++) {
        const keys = Object.keys(this.assignedProductsSelectionChecks)
        const pd = this.assignedProductsSelectionChecks[keys[i]]
        if (pd != null) {
          if (Number(this.assignedDiscounts[pd]) < 0 || Number(this.assignedDiscounts[pd]) > 100) {
            hasBreak = true
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: 'Entered discount is invalid',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
            break
          }
        }
      }
      if (hasBreak) return

      const pads = []
      if (this.selectedDiscountSlab == null) {
        this.discountSlabError = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select Discount slab',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.discountSlabError = false

      if (byArray) {
        Object.entries(this.productsSelectionChecks).forEach((a) => {
          if (a[1]) {
            pads.push(
              {
                'mode': 0,
                'product_configuration_id': a[0],
                'discount': this.discount[a[1]] ? this.discount[a[1]] : 0,
                'limit': this.assignedLimit[a[1]] ? this.assignedLimit[a[1]] : 0,
              },
            )
          }
        })
        Object.entries(this.assignedProductsSelectionChecks).forEach((a) => {
          if (a[1]) {
            pads.push({
              'mode': 1,
              'pad_id': a[0],
              'product_configuration_id': a[1],
              'discount': this.assignedDiscounts[a[1]] ? this.assignedDiscounts[a[1]] : 0,
              'limit': this.assignedLimit[a[1]] ? this.assignedLimit[a[1]] : 0,
            })
          }
        })
      } else {
        pads.push(
          {
            'mode': 0,
            'product_configuration_id': config.id,
            'discount': this.discount[config.id] ? this.discount[config.id] : this.selectedDiscountSlab.plan_discount,
            'limit': this.assignedLimit[config.id] ? this.assignedLimit[config.id] : 0
          },
        )
      }
      const operations = {userId: this.customerId, padList: JSON.stringify(pads)}
      if (this.selectedDiscountSlab) {
        operations.discount_plan_id = this.selectedDiscountSlab.plan_id
      }
      this.$vs.loading()
      return this.$store.dispatch('pad/operationOnPAD', operations)
        .then((data) => {
          this.$vs.loading.close()
          this.productsSelectionChecks = {}
          this.loadProducts()
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    reFetchDiscounts() {
      this.checkedAll = false
      this.assignedCheckedAll = false
      const obj = {}
      this.allAssignedProducts.forEach((p) => {
        obj[p.id] = p.discount
      })
      this.assignedDiscounts = obj

      this.products.forEach((p) => {
        if (this.productsFilterArray.indexOf(p.product_name) === -1) this.productsFilterArray.push(p.product_name)
        if (this.denominationsFilterArray.indexOf(p.value) === -1) this.denominationsFilterArray.push(p.value)
      })
      this.allAssignedProducts.forEach((p) => {
        if (this.assignedProductsFilterArray.indexOf(p.product_name) === -1) this.assignedProductsFilterArray.push(p.product_name)
        if (this.assignedDenominationsFilterArray.indexOf(p.value) === -1) this.assignedDenominationsFilterArray.push(p.value)
      })

      this.productsSelectionChecks = {}
      this.assignedProductsSelectionChecks = {}

      this.fetchDiscountSlabsProducts()
    },
    removeConfigFromAssigned(config, byArray, isUpdate = false) {
      const pads = []
      if (isUpdate) {
        // { "mode":1,  "pad_id":4, "discount":"10", "product_configuration_id":1, "discount":"23.4" }
        if (byArray) {
          Object.entries(this.assignedProductsSelectionChecks).forEach((a) => {
            if (a[1]) {
              pads.push({
                'mode': 1,
                'pad_id': a[0],
                'discount': this.assignedDiscounts[a[1]] ? this.assignedDiscounts[a[1]] : 0,
              })
            }
          })
        } else {
          pads.push(
            {'mode': 2, 'pad_id': config.pad_id},
          )
        }
      } else if (byArray) {
        Object.entries(this.assignedProductsSelectionChecks).forEach((a) => {
          if (a[1]) {
            pads.push({'mode': 2, 'pad_id': a[0]})
          }
        })
      } else {
        pads.push(
          {'mode': 2, 'pad_id': config.pad_id},
        )
      }
      const operations = {userId: this.customerId, padList: JSON.stringify(pads)}
      operations.discount_plan_id = 1
      this.$vs.loading()
      return this.$store.dispatch('pad/operationOnPAD', operations)
        .then((data) => {
          this.$vs.loading.close()
          this.assignedProductsSelectionChecks = {}
          this.loadProducts()
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadProducts() {
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchData', {
        userId: this.customerId,
        productType: this.selectedProductType
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.reFetchDiscounts()

          let limitObj = {};
          this.allAssignedProducts.forEach((p) => {
            limitObj[p.id.toString()] = p.limit
          })
          this.productLimits = limitObj

          // if(this.isInit) {
          //   this.fetchDiscountSlabs()
          //   this.isInit = false;
          // }
          // this.fetchDiscountSlabs()
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchDiscountSlabs() {
      this.$vs.loading()
      return this.$store.dispatch('pad/fetchDiscountSlabs')
        .then((data) => {
          this.$vs.loading.close()
          this.updateDiscountSlab()
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: $t('ErrorDiscountSlabs'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCustomer() {
      this.$store.dispatch('customer/fetchCustomerDetails', this.customerId)
        .then((data) => {
          this.customer = data
          this.updateDiscountSlab()
        })
        .catch((error) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {

  },
  mounted() {

    this.$store.commit('pad/SET_PRODUCTS_DATA', [])
    this.$store.commit('pad/SET_PRODUCTS_ASSIGNED_DATA', [])
    this.isMounted = true
    this.fetchDiscountSlabs()
    // this.fetchDiscountSlabsProducts();
    this.fetchCustomer()
  },
}
</script>

<style lang="scss">
.vs-button.small {
  padding: 7px 0;
  font-size: .7em;
}

.alpha-clear {
  margin-top: 0.5rem;
  padding: 5px;
}

.vs-button.small:not(.includeIconOnly) {
  padding: 7px 0;
}

#products-list-list-view {

  .vs-inputx {
    width: 82px;
    text-align: center;
    place-content: center;
  }

  .product-image {
    /*max-width: 200px;*/
    max-height: 30px;
    object-fit: cover;
  }

  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .products-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.tr-update {
  background-color: #96e6a1ba;
}
</style>
